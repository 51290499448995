@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
}

.dark {
    --background: 0 0% 12%; /* #1E1E1E en HSL */
    --foreground: 0 0% 83%; /* #D4D4D4 en HSL */
    --muted: 0 0% 18%; /* #2D2D2D en HSL */
    --muted-foreground: 0 0% 63%;
    --popover: 0 0% 12%;
    --popover-foreground: 0 0% 83%;
    --card: 0 0% 15%; /* #252526 en HSL */
    --card-foreground: 0 0% 83%;
    --border: 0 0% 18%;
    --input: 0 0% 18%;
    --primary: 210 100% 52%; /* #0078D4 en HSL, couleur d'accentuation de VS Code */
    --primary-foreground: 0 0% 100%;
    --secondary: 0 0% 18%;
    --secondary-foreground: 0 0% 83%;
    --accent: 0 0% 18%;
    --accent-foreground: 0 0% 83%;
    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 83%;
    --ring: 0 0% 18%;
    --radius: 0.5rem;
}

body {
    font-feature-settings:
        'rlig' 1,
        'calt' 1;
    min-height: 100vh;
    transition: background-color 0.3s ease;
}

body,
.bg-transition {
    transition: background-color 0.3s ease;
}

.monaco-editor .scrollbar .slider {
    background: rgba(100, 100, 100, 0.1) !important;
}

.monaco-editor .scrollbar .slider:hover {
    background: rgba(100, 100, 100, 0.3) !important;
}
